import React from "react";
import { graphql } from "gatsby";
import { ThemeContext } from "../layouts";
import Item from "../components/Blog/Item";
import NextPrev from "../components/Post/NextPrev";

const BlogPageTemplate = props => {
  const { group, first, last, index } = props.pageContext;
  const data = props.data;

  let nextParam = { title: "Newer", slug: `page${index - 1}` };
  if (first) {
    nextParam.slug = "/";
  }
  let prevParam = { title: "Older", slug: `page${index + 1}` };
  if (last) {
    prevParam.slug = null;
  }

  return (
    <ThemeContext.Consumer>
      {theme => (
        <React.Fragment>
          <main className="main">
            <ul>
              {data.posts.edges.map(post => {
                const {
                  node,
                  node: {
                    fields: { slug }
                  }
                } = post;
                return <Item key={slug} post={node} theme={theme} />;
              })}
            </ul>
            <div>
              <NextPrev next={nextParam} prev={prevParam} theme={theme} />
            </div>
          </main>
          <style jsx>{`
            .main {
              padding: 0 ${theme.space.inset.default};
            }

            ul {
              list-style: none;
              margin: 0 auto;
              padding: ${`calc(${theme.space.default} * 1.5) 0 calc(${theme.space.default} * 0.5)`};
            }

            @above tablet {
              .main {
                padding: 0 ${`0 calc(${theme.space.default} * 1.5)`};
              }
              ul {
                max-width: ${theme.text.maxWidth.tablet};
              }
              div {
                max-width: ${theme.text.maxWidth.tablet};
              }
            }
            @above desktop {
              ul {
                max-width: ${theme.text.maxWidth.desktop};
              }
              div {
                max-width: ${theme.text.maxWidth.desktop};
              }
            }
            div {
              text-align: center;
              margin: 0 auto;
            }
          `}</style>
        </React.Fragment>
      )}
    </ThemeContext.Consumer>
  );
};

export const blogPageQuery = graphql`
  query BlogPageQuery($slugsRegex: String) {
    posts: allMarkdownRemark(
      filter: { fields: { slug: { regex: $slugsRegex } } }
      sort: { fields: [fields___prefix], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            prefix
          }
          frontmatter {
            title
            category
            author
            cover {
              children {
                ... on ImageSharp {
                  fluid(maxWidth: 800, maxHeight: 360) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
  }
`;

export default BlogPageTemplate;
